import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ChevronDownIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
	Breadcrumb,
	BreadcrumbItem,
	BreadcrumbLink,
	Button,
	Center,
	Heading,
	HStack,
	Input,
	Menu,
	MenuButton,
	MenuItem,
	MenuList,
	Skeleton,
	Text,
	useDisclosure,
	VStack,
} from '@chakra-ui/react';

import AlertDialog from 'components/AlertDialog';
import Cardlayout from 'components/CardLayout';
import CategoriesTable from 'components/tables/CategoriesTable';
import DocumentsList from 'features/DocumentsList';
import useFund from 'hooks/useFund';
import { useNextStatusModal } from 'hooks/useNextStatusModal';
import useThemedToast from 'hooks/useThemedToast';
import useGetUserData from 'pages/ops/super/subscription/useDisplayUserInfo';
import { useGetClientKYCQuery } from 'services/client';
import { AllDeal, useDeleteDealMutation, useGetDealByIdQuery } from 'services/deal';
import { RamifyDoc } from 'services/document';
import { useDeleteTransferMutation, useGetTransferByIdQuery } from 'services/ops/transfer';
import { ProductType, SubscriptionStatus } from 'types/global.type';
import { SavingsAccountProvider } from 'types/savingsAccount.type';
import { isNone, isNotNone } from 'utils/functions';
import { PermissionDisplayGuard } from 'utils/guards';
import { BoPermission } from 'utils/permissions';

import SubscriptionSendApi, { ApiPartner } from './sender/SubscriptionSendApi';
import SubscriptionSendMail from './sender/SubscriptionSendMail';

const transferMailBody = `Bonjour,

Vous trouverez le bulletin de transfert du client cité en objet en pièce jointe.
Merci de me confirmer la bonne réception et traitement du document.

Bien à vous`;

const useFindDealOrTransfer = (productType: ProductType | 'TRANSFER') => {
	const { id } = useParams<{ id: string }>();

	const { data: possibleSubscription, isFetching: isSubscriptionFetching } = useGetDealByIdQuery(
		{ id: id ?? '', productType: productType as ProductType },
		{ skip: !isNotNone(id) || !isNotNone(productType) || productType === 'TRANSFER' },
	);
	const { data: possibleTransfer, isFetching: isTransferFetching } = useGetTransferByIdQuery(
		{ id: id ?? '' },
		{ skip: !isNotNone(id) || !isNotNone(productType) || productType !== 'TRANSFER' },
	);

	return useMemo(
		() => ({
			data: (possibleSubscription ?? possibleTransfer ?? undefined) as AllDeal | undefined,
			isFetching: isSubscriptionFetching || isTransferFetching,
		}),
		[isSubscriptionFetching, isTransferFetching, possibleSubscription, possibleTransfer],
	);
};

export const SubscriptionDetails = () => {
	const navigate = useNavigate();
	const toast = useThemedToast();
	const location = useLocation();
	const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
	const productType = useMemo(() => queryParams.get('productType') as ProductType | 'TRANSFER', [queryParams]);

	const { data: subscription, isFetching: isSubscriptionFetching } = useFindDealOrTransfer(productType);
	const { data: kyc } = useGetClientKYCQuery({ email: subscription?.user.email ?? '' }, { skip: isNone(subscription) });
	const [deleteDeal, { isLoading: isDeleteDealLoading }] = useDeleteDealMutation();
	const [deleteTransfer, { isLoading: isDeleteTransferLoading }] = useDeleteTransferMutation();

	const fund = useFund(subscription, subscription?.status !== SubscriptionStatus.SIGNED);
	const categoriesData = useGetUserData({ email: subscription?.user.email, subscription });
	const { NextStatusModal, handleOpenNextStatusModal } = useNextStatusModal();
	const { isOpen: isDeleteDialogOpen, onOpen: openDeleteDialog, onClose: closeDeleteDialog } = useDisclosure();

	const [selectedDocuments, setSelectedDocuments] = useState<RamifyDoc[]>([]);
	const [validationInput, setValidationInput] = useState<string>('');
	const [partnerEmail, setParterEmail] = useState<string>('');

	// if sub is girardin or non auto process for pe/scpi/crowd or not anaxago
	const showNextStatusbutton = useMemo(() => {
		if (isNone(subscription)) return false;

		if (subscription.status === SubscriptionStatus.COMPLETED) return false;

		if (subscription.productType === ProductType.GIRARDIN) return true;
		if (subscription.productType === ProductType.AV_LUX) return true;

		if (
			subscription.productType === ProductType.PE ||
			subscription.productType === ProductType.SCPI ||
			subscription.productType === ProductType.CROWDFUNDING ||
			subscription.productType === ProductType.ART
		) {
			if (subscription.productType === ProductType.CROWDFUNDING && subscription.provider === 'Anaxago') return false;
			if (subscription.process === 'extranet' || subscription.process === 'paper') return true;
		}

		return false;
	}, [subscription]);

	useEffect(() => {
		if (!subscription || !fund) return;
		setParterEmail(fund['Email - MO/BO'] ?? '');
	}, [subscription, fund]);

	const handleDeleteSubscription = async () => {
		(productType === 'TRANSFER'
			? deleteTransfer({ id: subscription!.id })
			: deleteDeal({
					id: subscription!.id,
					productType: subscription!.productType,
					opsPropertiesId: subscription!.opsPropertiesId,
					partnerEmail: partnerEmail.length > 0 ? partnerEmail : undefined,
			  })
		)
			.unwrap()
			.then(() => {
				setValidationInput('');
				setParterEmail('');
				closeDeleteDialog();
				navigate('..');
				toast({ status: 'success', title: 'Souscription supprimée avec succès' });
			})
			.catch((err) => toast({ status: 'error', title: 'Erreur', description: err.data.message }));
	};

	if (isSubscriptionFetching) return <Skeleton h="100%" w="100%" />;
	if (isNone(subscription))
		return (
			<Center>
				<Heading size="md">Souscription non trouvée</Heading>
			</Center>
		);

	return (
		<VStack w="100%" spacing="12px" align="start" pb="32px">
			<NextStatusModal />

			<Breadcrumb spacing="8px" separator={<ChevronRightIcon color="gray.500" />}>
				<BreadcrumbItem>
					<BreadcrumbLink onClick={() => navigate('..')}>Souscriptions</BreadcrumbLink>
				</BreadcrumbItem>

				<BreadcrumbItem>
					<BreadcrumbLink>{subscription.user.email}</BreadcrumbLink>
				</BreadcrumbItem>
			</Breadcrumb>

			<HStack w="100%" align="start" justify="space-between">
				<Heading size="lg">
					{kyc?.kyc?.firstName} {kyc?.kyc?.lastName} - {subscription.productType}
				</Heading>

				<HStack align="start">
					{showNextStatusbutton && (
						<Button colorScheme="blue" onClick={() => handleOpenNextStatusModal(subscription)}>
							Passer au status suivant
						</Button>
					)}

					<Button
						colorScheme="blue"
						onClick={() => navigate('/ops/super/blocking-instance', { state: { deal: subscription } })}
					>
						Créer une instance bloquante
					</Button>

					<Menu closeOnSelect={false}>
						<MenuButton as={Button} rightIcon={<ChevronDownIcon />}>
							Actions
						</MenuButton>
						<MenuList>
							<MenuItem onClick={() => navigate(`/ops/super/client/${subscription.user.id}`)}>Page client</MenuItem>
							<PermissionDisplayGuard permission={BoPermission.DEAL_DELETE}>
								<MenuItem color="red.600" onClick={openDeleteDialog}>
									Supprimer la souscription
								</MenuItem>
							</PermissionDisplayGuard>
						</MenuList>
					</Menu>
				</HStack>
			</HStack>

			<VStack w="100%" spacing="12px" align="start">
				<CategoriesTable categoriesData={categoriesData} title="Récapitulatif" />

				<DocumentsList
					context="subscription"
					email={subscription.user.email}
					subscription={subscription}
					selectedDocuments={selectedDocuments}
					setSelectedDocuments={setSelectedDocuments}
				/>

				{!showNextStatusbutton && (
					<Cardlayout title="Transmettre le dossier">
						{/* AIRTABLE */}
						{subscription.productType === ProductType.PE && subscription.process === 'api' && (
							<SubscriptionSendApi
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
								partner={subscription.partner.toLowerCase() as ApiPartner}
							/>
						)}
						{subscription.productType === ProductType.PE && subscription.process === 'mail' && (
							<SubscriptionSendMail
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
							/>
						)}
						{subscription.productType === ProductType.SCPI && subscription.process === 'mail' && (
							<SubscriptionSendMail
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
							/>
						)}
						{subscription.productType === ProductType.ART && (
							<SubscriptionSendMail
								defaultReceiver="andrea@matis.club"
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
							/>
						)}

						{/* anaxago subs are a special case: they are sent via email, but tagged as extranet on airtable because they are not in app signed */}
						{subscription.productType === ProductType.CROWDFUNDING &&
							(subscription.process === 'mail' || subscription.provider === 'Anaxago') && (
								<SubscriptionSendMail
									defaultReceiver={subscription.provider === 'Anaxago' ? 'alexandre.trigo@anaxago.com' : undefined}
									selectedDocuments={selectedDocuments}
									setSelectedDocuments={setSelectedDocuments}
									subscription={subscription}
								/>
							)}

						{/* CASH */}
						{subscription.productType === ProductType.CASH &&
							subscription.provider === SavingsAccountProvider.CFCAL && (
								<SubscriptionSendApi
									selectedDocuments={selectedDocuments}
									setSelectedDocuments={setSelectedDocuments}
									subscription={subscription}
									partner={ApiPartner.CFCAL}
								/>
							)}

						{/* INVEST */}
						{subscription.productType === ProductType.INVEST && subscription.dealType !== 'TRANSFER' && (
							<SubscriptionSendApi
								selectedDocuments={selectedDocuments}
								setSelectedDocuments={setSelectedDocuments}
								subscription={subscription}
								partner={ApiPartner.APICIL}
							/>
						)}

						{/* TRANSFER */}
						{subscription.productType === ProductType.INVEST &&
							subscription.dealType === 'TRANSFER' &&
							'contractNumber' in subscription && (
								<SubscriptionSendMail
									selectedDocuments={selectedDocuments}
									setSelectedDocuments={setSelectedDocuments}
									subscription={subscription}
									isTransfert
									defaultText={transferMailBody}
									defaultReceiver="operations.epargne@intencial.fr"
									defaultSubject={`Transfert PER | ${subscription.user.kyc.firstName} ${
										subscription.user.kyc.lastName
									} | ${
										!subscription.numContratApicil
											? '[EN COURS DE SOUSCRIPTION]'
											: `Numéro de contrat ${subscription.numContratApicil}`
									}`}
								/>
							)}
					</Cardlayout>
				)}
			</VStack>

			<AlertDialog
				isOpen={isDeleteDialogOpen}
				onClose={closeDeleteDialog}
				header="Supprimer la souscription"
				body={
					<VStack spacing="16px" align="start">
						<Text>Vous êtes sur le point de supprimer cette souscription.</Text>
						{subscription.status === SubscriptionStatus.SIGNED && (
							<>
								<Text>Email du partenaire:</Text>
								<Input
									value={partnerEmail}
									onChange={(e) => setParterEmail(e.target.value)}
									placeholder="Email du partenaire"
								/>
							</>
						)}
						<Text>
							Veuillez confirmer cette opération en écrivant <i>valider la suppression</i>
						</Text>
						<Input
							value={validationInput}
							onChange={(e) => setValidationInput(e.target.value)}
							placeholder="valider la suppression"
						/>
					</VStack>
				}
				footer={
					<>
						<Button onClick={closeDeleteDialog}>Annuler</Button>
						<Button
							colorScheme="red"
							ml={3}
							isLoading={isDeleteDealLoading || isDeleteTransferLoading}
							isDisabled={validationInput !== 'valider la suppression'}
							onClick={handleDeleteSubscription}
						>
							Valider
						</Button>
					</>
				}
			/>
		</VStack>
	);
};
