export const fieldTranslation = {
	createdAt: 'Créé le',
	id: 'ID',
	updatedAt: 'Mis à jour le',
	signedAt: 'Signé le',
	closedAt: 'Clôturé le',
	name: 'Nom',
	lastName: 'Nom',
	firstName: 'Prénom',
	civilStatus: 'Statut civil',
	familySituation: 'Situation familiale',
	matrimonialRegime: 'Régime matrimonial',
	citizenship: 'Nationalité',
	birthDate: 'Date de naissance',
	birthCountry: 'Pays de naissance',
	birthCity: 'Ville de naissance',
	birthZipCode: 'Code postal de naissance',
	birthCityCode: 'Code INSEE de naissance',
	birthName: 'Nom de naissance',
	phoneNumber: 'Téléphone',
	phoneNumberCode: 'Code téléphone pays',
	country: 'Pays',
	city: 'Ville',
	overseasOrMetropol: 'Outre-mer ou Métropole',
	otherIncome: 'Autres revenus',
	professionalSector: 'Secteur professionnel',
	professionalCategory: 'Catégorie professionnelle',
	professionalSituation: 'Situation professionnelle',
	companyName: "Nom de l'entreprise",
	profession: 'Profession',
	selfEmployed: 'Est indépendant',
	siret: 'SIRET',
	patrimonyEstimatePrimaryRealEstate: 'Immobilier - résidence principale',
	patrimonyEstimateSecondaryRealEstate: 'Immobilier - résidence secondaire',
	patrimonyEstimateBankAccount: 'Comptes bancaires et liquidités',
	patrimonyEstimatePortfolio: 'Portefeuilles valeurs mobilières ',
	patrimonyEstimateProfessionalAssets: 'Actifs professionnels',
	patrimonyEstimateCcontractAVCapitalisation: 'Contrat d’assurance-vie, de capitalisation',
	patrimonyEstimateRetirement: 'Contrat d’épargne retraite',
	patrimonyEstimateOther: 'Autres (art, or, montres)',
	patrimonySources: 'Sources du patrimoine',
	childNumber: "Nombre d'enfants",
	revenu: 'Revenu',
	revenuPeriod: 'Période de revenu',
	revenuSpouse: 'Revenu conjoint',
	revenuSpousePeriod: 'Période de revenu conjoint',
	revenuSpouseType: 'Type de revenu conjoint',
	revenuType: 'Type de revenu',
	nif: 'NIF',
	nir: 'NIR',
	patrimonyNonCote: 'Private Equity',
	patrimonyRealEstate: 'Immobilier locatif',
	primaryResidenceStatus: 'Statut résidence principale',
	hasRealEstateLoan: 'A un emprunt immobilier',
	realEstateLoanFrequency: 'Fréquence paiement emprunt immobilier',
	realEstateLoanRest: 'Capital restant dû emprunt immobilier',
	realEstateLoanAmount: "Mensualité / annuité de l'emprunt immobilier",
	hasConsomationLoan: 'A emprunt consommation',
	consomationLoanFrequency: 'Fréquence paiement emprunt consommation',
	consomationLoanRest: 'Capital restant dû emprunt consommation',
	consomationLoanAmount: 'Mensualité / annuité des emprunts consommation',
	hasOtherLoan: 'Crédit autre',
	otherLoanFrequency: 'Fréquence paiement crédit autre',
	otherLoanRest: 'Capital dû restant crédit autre',
	otherLoanAmount: 'Mensualité / annuité des autres crédits',
	savingCapacity: "Capacité d'épargne",
	hasExercisedFinancialProfession: 'A exercé une profession dans le secteur financier',
	hasPEShares: 'Détient plus de 25 % de parts dans une société',
	hasPELinks: 'A des liens avec des sociétés cotées',
	riskPerception: 'Perception du risque',
	lengthPerception: 'Selon lui/elle, horizon investissement PE',
	PELinksNames: 'Noms des sociétés avec liens',
	peaKnowledge: 'Connaissances PEA',
	avKnowledge: 'Connaissances AV',
	opcvmMonetaireKnowledge: 'Connaissances OPCVM Monetaire',
	opcvmActionKnowledge: 'Connaissances OPCVM Action',
	nonCoteKnowledge: 'Connaissances actions non côtés',
	coteKnowledge: 'Connaissances actions côtés',
	produitStrucutureKnowledge: 'Connaissances Produit Strucuture',
	agreeWithInvestment: 'Le placement est adapté à votre situation',
	PESharesNames: 'Nom de cette société',
	fundsOrigin: 'Origine des fonds',
	isPoliticallyExposed: 'Personne politiquement exposée',
	isUSPerson: 'US Person',
	address: 'Addresse',
	zipCode: 'Code postal',
	legalEntityType: 'Entité légale',
	legalEntity: 'Nom entité légale',
	cgpAccountId: 'ID Compte CGP',
	underCgpAccess: 'Accès CGP',
	taxType: 'Type d’imposition',
	taxAmount: 'Montant des taxe',
	hasIFI: "Soumis à l'IFI",
	taxIFIAmount: 'Montant IFI',
	initialPriceShare: 'Prix initial de la part',
	WinLoss: 'Plus-value / moins-value',
	MontantSouscrit: 'Montant souscrit',
	immoRevenusBrut: 'Revenus bruts immobiliers',
	lastApicilActualizationAt: 'Dernière actualisation Apicil',
	investKnowledge: 'Connaissances en investissement',
	hasRealEstateInvest: 'A investi dans l’immobilier',
	hasSCPIShares: 'A investi dans des SCPI',
	hasInvestedMoreThan100k: 'A investi plus de 100k€',
	hasLongTermInvestmentConscience: 'A conscience de l’investissement long terme',
	investReturnPerception: 'Perception du rendement',
	investRevenusPerception: 'Perception des revenus',
	investRevenusLengthPerception: 'Perception de la durée des revenus',
	PELinksTypes: 'Types de liens avec les sociétés PE',
	objectives: 'Objectifs',
	projectType: 'Type de projet',
	status: 'Statut',
	demembrementDuration: 'Durée du démembrement (années)',
	ownerEmail: 'Email',
	registeredAt: "Date d'enregistrement",
	fundType: 'Type de fond',
	effectiveDate: 'Date effective',
	fundsOriginGeography: 'Origine géographique des fonds',
	email: 'Email',
	nbShare: 'Nombre de parts',
	bankInformationId: 'ID compte bancaire',
	amountVersement: 'Montant du versement',
	lastShareValuation: 'Dernière valorisation des parts',
	valuation: 'Valorisation',
	fundPartnerName: 'Nom du partenaire',
	fundName: 'Nom du fond',
	lastValuationDate: 'Date de dernière valorisation',
	initialAUM: 'AUM initial',
	externalAccountId: 'ID compte externe',
	timeHorizon: 'Horizon de temps',
	moneySupplyFrequency: 'Fréquence de versement',
	type: 'Type',
	provider: 'Fournisseur',
	promotionalOfferRate: 'Taux promotionnel',
	standardRate: 'Taux standard',
	promotionalOfferDuration: 'Durée de la promotion',
	openingDate: 'Date d’ouverture',
	endDate: 'Date de fin',
	synchronisedAt: 'Date de synchronisation',
	effectDate: 'Date d’effet',
	numContratApicil: 'Numéro de contrat Apicil',
	apicilSubscriptionId: 'Numéro de souscription Apicil',
	amount: 'Montant',
	initialDepositAmount: 'Montant du dépôt initial',
	recurrentDepositAmount: 'Montant des versements prgrammés',
	recurrentDepositperiod: 'Fréquence des versements programmés',
	recurrentDepositDay: 'Jour de prélevement APICIL',
	fundOriginType: 'Origine des fonds',
	beneficiary: 'Bénéficiaire',
	freeBeneficiaryClause: 'Clause bénéficiaire libre',
	unvaluedBalance: 'Solde non valorisé',
	totalVersement: 'Total des versements',
	partnerCode: 'Code produit partenaire (enveloppe)',
	lastRebalancingAt: 'Dernier Ré-arbitrage',
	productType: 'Type de produit',
	overrideOverdueDate: "Date d'échéance",
	assignedOpsEmail: 'Responsable OPS',
	comment: 'Commentaire OPS',
	dealType: 'Type de deal',
	representativeType: 'Type de représentant',
	revenuOrigin: 'Origine des revenus',
	revenuSpouseOrigin: 'Origine des revenus conjoint',
	taxNoticeFileId: 'ID du dernier avis d’imposition',
	numberOfFiscalPart: 'Nombre de parts fiscales',
	numberOfDisabledPeopleOtherThanChildren: 'Nombre de personnes à charge',
	numberOfDependentChildren: 'Nombre d’enfants à charge',
	numberOfMinorChildrenInPrimaryResidence: 'Nombre d’enfants mineurs en résidence principale',
	numberOfDisabledChildrenInPrimaryResidence: 'Nombre d’enfants handicapés en résidence principale',
	numberOfMinorChildrenInSharedResidence: 'Nombre d’enfants mineurs en résidence partagée',
	numberOfDisabledChildrenInSharedResidence: 'Nombre d’enfants handicapés en résidence partagée',
	numberOfMajorSingleChildren: 'Nombre d’enfants majeurs célibataires',
	isHolderForPensionDisabilityCard: 'Titulaire d’une carte d’invalidité',
	partnerIsHolderForPensionDisabilityCard: 'Conjoint titulaire d’une carte d’invalidité',
	isTookInLastFiveYearOneChild: 'A eu un enfant au cours des 5 dernières années',
	isSingleWithChildrenOrDisabledPerson: 'Célibataire avec enfants ou personne handicapée',
	salaryGrossPerYear: 'Salaire brut annuel',
	spouseSalaryGrossPerYear: 'Salaire brut annuel conjoint',
	pensionRentIncome: 'Revenus de pension ou de rente',
	spousePensionRentIncome: 'Revenus de pension ou de rente conjoint',
	landIncome: 'Revenus fonciers',
	spouseLandIncome: 'Revenus fonciers conjoint',
	haveMicroLandStatus: 'Micro foncier',
	tnsFarmingIncome: 'Revenus agricoles',
	tnsFarmingStatus: 'Micro BIC',

	outsidePer: 'Investissements en PER hors Ramify',
	outsideFCPI: 'Investissements en FCPI hors Ramify',
	outsideFIP: 'Investissements en FIP hors Ramify',
	outsideCorseFIP: 'Investissements en FIP Corse hors Ramify',
	outsideDomTomFip: 'Investissements en FIP Dom Tom hors Ramify',
	otherTaxReduction: 'Autres réductions d’impôts',
	girardinTaxReduction: 'Réductions d’impôts Girardin',
	girardinTaxReductionPercentage: 'Girardin - Pourcentage pris en compte',

	investedAmount: 'Montant investi',
	prefObjectives: 'Objectifs',

	message: 'Message',
	commentOps: 'Commentaire OPS',
	product: 'Produit',
	lifecycle: 'Cycle de vie',
	missingDocument: 'Documents manquants',
	partner: 'Partenaire',
	mailSubject: 'Sujet du mail',

	fiscalAddress: 'Adresse fiscale',
	franceFiscalResident: 'Résident fiscal en France',
	otherCountryFiscalResident: 'Résident fiscal dans un autre pays',
	otherCountryFiscalNif: 'NIF fiscal dans un autre pays',
	firstNameSpouse: 'Prénom conjoint',
	lastNameSpouse: 'Nom conjoint',
	professionSpouse: 'Profession conjoint',
	notOtherFiscalResident: 'Non résident fiscal dans un autre pays',
	fiscalAddressCity: 'Ville adresse fiscale',
	nbPrivateEquityTransactions: 'Nombre de transactions PE',
	nbRealEstateTransactions: 'Nombre de transactions immobilières',
	hasStudiedInFinance: 'A étudié la finance',
	hasActionsOrObligations: 'Détient des actions ou obligations',
	addressStreetNumber: 'Numéro de rue',
	lastResidualCapitalPercentage: 'Dernier pourcentage de capital résiduel',
	fundByCapitalCalls: 'Fonds par appels de capital',
	taxReduction: 'Réduction d’impôts',
	legalCapacity: 'Capacité juridique',
	fiscalAddressZipCode: 'Code postal adresse fiscale',
	trackers: 'ETF',

	obligation: 'Obligation',
	scpi: 'SCPI',
	opc: 'OPC',
	opci: 'OPCI',
	crowdfunding: 'Crowdfunding',

	fondEuro: 'Fonds Euro',
	averageAmount: 'Montant moyen',
	riskPerceptionScpi: 'Perception du risque SCPI',
	lastOperation: 'Dernière opération',
	lengthPerceptionScpi: 'Perception de la durée SCPI',
	riskPerceptionPe: 'Perception du risque PE',
	lengthPerceptionPe: 'Perception de la durée PE',
	riskPerceptionCwdfd: 'Perception du risque Crowdfunding',
	lengthPerceptionCwdfd: 'Perception de la durée Crowdfunding',
	cwdfdSignedAt: 'Date de signature Crowdfunding',
	riskPerceptionArt: 'Perception du risque ART',
	lengthPerceptionArt: 'Perception de la durée ART',

	naf: 'Naf',
	siren: 'Siren',
	activity: 'Activité',
	constitutionDate: 'Date de constitution',
	juridicForm: 'Forme juridique',
	legalRepresentativeFunction: 'Fonction du représentant légal',
	legalRepresentativeEntryDate: 'Date d’entrée du représentant légal',
	nonFinancialEntityType: 'Entité non financière',
	siegeAddress: 'Adresse du siège',
	siegeAddressDetails: 'Complément - Adresse du siège',
	siegeCity: 'Ville du siège',
	correspondanceAddress: 'Adresse de correspondance',
	correspondanceAddressDetails: 'Complément - Adresse de correspondance',
	correspondanceCity: 'Ville de correspondance',
	correspondanceCityCode: 'Adresse postale ville de correspondance',
	registrationCity: 'Ville d’immatriculation',
	hasTurnoverFromExportation: 'A du CA issu d’exportations',
	exportationCountries: 'Pays d’exportation',
	immatriculationCountry: 'Pays d’immatriculation',
	fundOriginDocument: 'Document d’origine des fonds',
	fundOrigin: 'Origine des fonds',
	fundOriginDetails: 'Détails sur l’origine des fonds',
	Turnover: 'Chiffre d’affaires',
	netIncome: 'Résultat net',
	Treasury: 'Trésorerie',
	moralPersonBeneficiaries: 'Bénéficiaires de la société',

	moralPersonId: 'ID personne morale',

	label: 'Libellé',
	bic: 'BIC',
	iban: 'IBAN',
	holder: 'Titulaire',

	expectedTaxReduction: 'Réduction d’impôts attendue',
	dividendsDistributionFrequency: 'Fréquence des distributions',

	didInitialDeposit: 'A fait son premier versement',
	rateTable: 'Taux',
	acceptInformationTransmission: 'Accepte la transmission d’informations',
	pastAccount: 'déjà client chez le partenaire',
	investmentDuration: 'Durée d’investissement',

	// theses translations are here and not separated because they are used in subscription section which is used in accordion component
	ofRevenusPro: 'Salaires et Revenus',
	ofSaving: 'Epargne déjà constituée',
	ofCessionActifs: 'Cession',
	ofSuccessionDonation: 'Donation / Héritage',
	ofVente: 'Vente de bien immobilier / Mobilier',

	ADDITIONNAL_REVENUE: 'Revenu additionnel',
	PATRIMONY_CONSTITUTION: 'Constitution d’un patrimoine',
	FISCAL_OPTIMISATION: 'Optimisation fiscale',
	PATRIMONY_DIVERSIFICATION: 'Diversification de patrimoine',
	PATRIMONY_TRANSMITION: 'Transmission de patrimoine aux proches',
	OTHER: 'Autre',
};
