import { FC, useCallback, useMemo, useState } from 'react';
import {
	Box,
	Button,
	Divider,
	FormControl,
	FormLabel,
	HStack,
	Input,
	Radio,
	RadioGroup,
	Select,
	Skeleton,
	Text,
	Textarea,
	VStack,
} from '@chakra-ui/react';
import { MultiValue, Select as MultiSelect } from 'chakra-react-select';

import NumberInputWithStepper from 'components/NumberInput';
import useThemedToast from 'hooks/useThemedToast';
import { CrowdfundingFundCard } from 'pages/cpm/product-propale/cards/CrowdfundingFundCards';
import ProductAccordion from 'pages/cpm/product-propale/cards/ProductAccordion';
import useAddProduct from 'pages/cpm/product-propale/useAddProduct';
import { AssetCPM } from 'services/cpm/product-propale';
import { CPM, cpmDisplayName } from 'types/cpm-list.type';
import { SavingsAccountType } from 'types/savingsAccount.type';

import { ArtCard } from './cards/ArtCard';
import { AvLuxCard } from './cards/AvLuxCard';
import { CashCard } from './cards/CashCards';
import { GirardinCard } from './cards/GirardinCard';
import { InvestCard } from './cards/InvestCard';
import { PeFundCard } from './cards/PeFundCards';
import { ScpiFundCard } from './cards/ScpiFundCards';
import StructuredCard from './cards/StructuredCard';
import { avLuxSelectOptions, cashSelectOptions, getFundList, sortString } from './utils';

type ProductPropaleProps = {
	isInDrawer?: boolean;
	customCpm?: CPM;
	customEmail?: string;
	customNotionLink?: string;
	onPostCreate?: (ids: Record<string, string[]>, amount: number) => Promise<void>;
};

export const ProductPropaleNoBox: FC<ProductPropaleProps> = ({
	isInDrawer,
	customCpm,
	customEmail,
	customNotionLink,
	onPostCreate,
}) => {
	const getUrlParams = new URLSearchParams(window.location.search);
	const toast = useThemedToast();

	const [selectedCPM, setSelectedCPM] = useState(customCpm ?? (getUrlParams.get('assignedCpm') as CPM));
	const [email, setEmail] = useState(customEmail ?? getUrlParams.get('clientEmail') ?? '');
	const [message, setMessage] = useState('');
	const [notionLink, setNotionLink] = useState(customNotionLink ?? getUrlParams.get('notionLink') ?? '');
	const [expectedReturnRate, setExpectedReturnRate] = useState('1');
	const [isBlack, setIsBlack] = useState<`${boolean}`>('false');
	const [useExpectedRR, setUseExpectedRR] = useState<`${boolean}`>('false');
	const [assets, SetAssets] = useState<MultiValue<Record<string, string>>>(
		(getUrlParams.get('assets') ?? '')
			.split(',')
			.filter((a) => Object.values(AssetCPM).includes(a as AssetCPM))
			.map((a) => ({ value: a, label: a })),
	);
	const [product, setProduct] = useState<string>();
	const [pickedFund, setPickedFund] = useState<string>();

	const {
		addFund,
		totalAmount,
		createPropale,
		isCreateLoading,
		selectedInvest,
		selectedScpi,
		selectedPe,
		selectedCrowdfunding,
		selectedCash,
		selectedArt,
		selectedGirardin,
		selectedAvLux,
		selectedStructured,
		setSelectedInvest,
		setSelectedScpiFunds,
		setSelectedPeFunds,
		setSelectedCrowdfunding,
		setSelectedCash,
		setSelectedArt,
		setSelectedGirardin,
		setSelectedAvLux,
		setSelectedStructured,
		scpiFunds,
		peFunds,
		artsFunds,
		crwdfgDeals,
		investFunds,
		structuredFunds,
		isFundsFetching,
	} = useAddProduct();

	const fundList = useMemo(
		() => getFundList(product, scpiFunds, peFunds, artsFunds, crwdfgDeals, structuredFunds),
		[product, scpiFunds, peFunds, artsFunds, crwdfgDeals, structuredFunds],
	);

	const handleAddFund = useCallback(() => {
		if (!product || !pickedFund) return;
		addFund(product, pickedFund);
		setPickedFund('');
	}, [product, pickedFund, addFund]);

	const handleCreatePropale = useCallback(
		() =>
			createPropale({
				ownerEmail: customEmail ?? email,
				cpm: customCpm ?? selectedCPM!,
				notionURL: customNotionLink ?? notionLink ?? undefined,
				message: message || undefined,
				estimatedDistributionRate: useExpectedRR === 'true' ? +expectedReturnRate / 100 : undefined,
				isBlack: isBlack === 'true',
				assets: assets.map((a) => a.value) as AssetCPM[],
			}).then((ids) => {
				// if we are in propale page drawer
				if (ids && isInDrawer && onPostCreate)
					onPostCreate(ids, totalAmount).catch(() =>
						toast({ status: 'error', title: 'Erreur lors de la mise à jour de la propale' }),
					);
			}),
		// eslint-disable-next-line prettier/prettier
		[createPropale, customEmail, email, customCpm, selectedCPM, customNotionLink, notionLink, message, useExpectedRR, expectedReturnRate, isBlack, assets, isInDrawer, onPostCreate, totalAmount, toast],
	);

	if (isFundsFetching) return <Skeleton h="300px" w="100%" />;
	if (!scpiFunds || !peFunds || !artsFunds || !crwdfgDeals || !investFunds || !structuredFunds)
		return <>Aucun fonds trouvé</>;

	return (
		<>
			<VStack align="start" spacing="20px">
				{!isInDrawer && (
					<>
						<HStack justify="space-between" w="100%" spacing="32px">
							<FormControl isRequired>
								<VStack w="100%" align="start" spacing="0px">
									<FormLabel fontSize="sm">CPM</FormLabel>
									<Select
										placeholder="Choisissez votre CPM"
										value={selectedCPM}
										onChange={(e) => setSelectedCPM(e.target.value as CPM)}
									>
										{Object.values(CPM).map((cpm) => (
											<option key={cpm} value={cpm}>
												{cpmDisplayName[cpm]}
											</option>
										))}
									</Select>
								</VStack>
							</FormControl>

							<FormControl isRequired>
								<VStack w="100%" align="start" spacing="0px">
									<FormLabel fontSize="sm" ml="0px !important">
										Adresse email du client
									</FormLabel>
									<Input type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
								</VStack>
							</FormControl>
						</HStack>

						<HStack align="start" justify="space-between" w="100%" spacing="32px">
							<FormControl>
								<VStack w="100%" align="start" spacing="0px">
									<FormLabel fontSize="sm">Message lié à la proposition</FormLabel>
									<Textarea value={message} onChange={(e) => setMessage(e.target.value)} />
								</VStack>
							</FormControl>

							<FormControl>
								<VStack w="100%" align="start" spacing="0px">
									<FormLabel fontSize="sm">Lien Notion</FormLabel>
									<Input
										type="text"
										value={notionLink}
										onChange={(e) => setNotionLink(e.target.value)}
										placeholder="https://notion.so/"
									/>
								</VStack>
							</FormControl>
						</HStack>
					</>
				)}

				<HStack justify="space-between" w="100%" spacing="32px">
					<FormControl>
						<VStack w="100%" align="start" spacing="0px">
							<FormLabel fontSize="sm" w="125px">
								Client Ramify Black
							</FormLabel>
							<RadioGroup onChange={(e) => setIsBlack(e === 'true' ? 'true' : 'false')} value={isBlack}>
								<HStack>
									<Radio size="sm" value="false">
										Non
									</Radio>
									<Radio size="sm" value="true">
										Oui
									</Radio>
								</HStack>
							</RadioGroup>
						</VStack>
					</FormControl>

					<FormControl>
						<HStack w="100%" align="end" spacing="0px">
							<VStack w="100%" align="start" spacing="0px">
								<FormLabel fontSize="sm">Spécifier un rendement</FormLabel>
								<RadioGroup onChange={(e) => setUseExpectedRR(e === 'true' ? 'true' : 'false')} value={useExpectedRR}>
									<HStack>
										<Radio size="sm" value="false">
											Non
										</Radio>
										<Radio size="sm" value="true">
											Oui
										</Radio>
									</HStack>
								</RadioGroup>
							</VStack>

							{useExpectedRR === 'true' && (
								<HStack w="100%" ml="64px !important">
									<NumberInputWithStepper
										precision={1}
										min={0}
										max={100}
										value={expectedReturnRate + '%'}
										onChange={(s) => setExpectedReturnRate(s)}
									/>
								</HStack>
							)}
						</HStack>
					</FormControl>
				</HStack>

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel fontSize="sm" w="225px">
							Classe d'actifs souhaités
						</FormLabel>
						<MultiSelect
							chakraStyles={{ container: (provided) => ({ ...provided, w: '100%' }) }}
							isMulti
							variant="outline"
							options={[
								{ label: 'Private Equity', value: 'Private Equity' },
								{ label: 'Immobilier', value: 'Immobilier' },
								{ label: 'Obligations', value: 'Obligations' },
								{ label: 'Actions', value: 'Actions' },
								{ label: 'Fonds Euro', value: 'Fonds euro' },
								{ label: 'Art', value: 'Art' },
							]}
							value={assets}
							placeholder="Selectionnez des actifs"
							onChange={(e) => SetAssets(e)}
						/>
					</HStack>
				</FormControl>

				<Divider />

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel fontSize="sm" w="125px">
							Produit
						</FormLabel>
						<RadioGroup
							value={product}
							onChange={(e) => {
								setPickedFund('');
								setProduct(e);
							}}
						>
							<HStack>
								<Radio size="sm" value="SCPI">
									SCPI
								</Radio>
								<Radio size="sm" value="PE">
									PE
								</Radio>
								<Radio size="sm" value="INVEST">
									Invest
								</Radio>
								<Radio size="sm" value="CER">
									CER
								</Radio>
								<Radio size="sm" value="CAT">
									CAT
								</Radio>
								<Radio size="sm" value="ART">
									Art
								</Radio>
								<Radio size="sm" value="CROWDFUNDING">
									Crowdfunding
								</Radio>
								<Radio size="sm" value="GIRARDIN">
									Girardin
								</Radio>
								<Radio size="sm" value="AV_LUX">
									AV Lux
								</Radio>
								<Radio size="sm" value="STRUCTURED">
									Structured
								</Radio>
							</HStack>
						</RadioGroup>
					</HStack>
				</FormControl>

				<FormControl isRequired>
					<HStack w="100%">
						<FormLabel fontSize="sm" w="150px">
							Fonds
						</FormLabel>
						<MultiSelect
							chakraStyles={{ container: (provided) => ({ ...provided, w: '100%' }) }}
							variant="outline"
							options={fundList
								.filter((f) => !!f.name)
								.sort((a, b) => sortString(a.name, b.name))
								.map((f) => ({ label: f.name, value: f.id, key: f.id }))}
							value={{
								label: fundList.find((f) => f.id === pickedFund)?.name,
								value: pickedFund,
							}}
							isDisabled={!product}
							placeholder="Selectionnez un fond"
							onChange={(e) => setPickedFund(e?.value)}
						/>
						<Button w="250px" isDisabled={!pickedFund} onClick={handleAddFund}>
							Ajouter le fond
						</Button>
					</HStack>
				</FormControl>

				<Divider />

				{selectedScpi.map((fund) => (
					<ProductAccordion
						key={fund.id}
						header={`SCPI - ${fund.fund.name}`}
						body={<ScpiFundCard data={fund} editData={setSelectedScpiFunds} />}
						onDelete={() => setSelectedScpiFunds((sf) => sf.filter((ff) => ff !== fund))}
					/>
				))}

				{selectedPe.map((fund) => (
					<ProductAccordion
						key={fund.id}
						header={`PE - ${fund.fund.name}`}
						body={<PeFundCard data={fund} editData={setSelectedPeFunds} />}
						onDelete={() => setSelectedPeFunds((sf) => sf.filter((ff) => ff !== fund))}
					/>
				))}

				{selectedInvest.map((i) => (
					<ProductAccordion
						key={i.id}
						header={`Invest - ${i.portfolioType} - ${i.envelope}`}
						body={<InvestCard data={i} editData={setSelectedInvest} />}
						onDelete={() => setSelectedInvest((sf) => sf.filter((ff) => ff !== i))}
					/>
				))}

				{selectedCash.map((c) => (
					<ProductAccordion
						key={c.id}
						header={`Cash - ${c.type} - ${c.legalEntity} - ${cashSelectOptions.find((f) => f.id === c.provider)?.name}`}
						body={<CashCard data={c} editData={setSelectedCash} />}
						onDelete={() => setSelectedCash((sf) => sf.filter((ff) => ff !== c))}
					/>
				))}

				{selectedAvLux.map((al) => (
					<ProductAccordion
						key={al.id}
						header={`AV Lux - ${avLuxSelectOptions.find((f) => f.id === al.provider)?.name}`}
						body={<AvLuxCard data={al} editData={setSelectedAvLux} />}
						onDelete={() => setSelectedAvLux((sa) => sa.filter((fa) => fa !== al))}
					/>
				))}

				{selectedArt.map((a) => (
					<ProductAccordion
						key={a.id}
						header={`Art - ${artsFunds.find((f) => f.id === a.fund.id)?.name}`}
						body={<ArtCard data={a} editData={setSelectedArt} />}
						onDelete={() => setSelectedArt((sa) => sa.filter((fa) => fa !== a))}
					/>
				))}

				{selectedCrowdfunding.map((a) => (
					<ProductAccordion
						key={a.id}
						header={`Crowdfunding - ${crwdfgDeals.find((f) => f.id === a.fund.id)?.name}`}
						body={<CrowdfundingFundCard data={a} editData={setSelectedCrowdfunding} />}
						onDelete={() => setSelectedCrowdfunding((sa) => sa.filter((fa) => fa !== a))}
					/>
				))}

				{selectedGirardin.map((g) => (
					<ProductAccordion
						key={g.id}
						header={`Girardin - ${g.provider}`}
						body={<GirardinCard data={g} editData={setSelectedGirardin} />}
						onDelete={() => setSelectedGirardin((sa) => sa.filter((fa) => fa !== g))}
					/>
				))}

				{selectedStructured.map((s) => (
					<ProductAccordion
						key={s.id}
						header={`Structuré - ${s.product.name}`}
						body={<StructuredCard data={s} editData={setSelectedStructured} />}
						onDelete={() => setSelectedStructured((sa) => sa.filter((fa) => fa !== s))}
					/>
				))}
			</VStack>

			<HStack mt="32px" justify="space-between">
				<Text>
					Montant a investir: <b>{totalAmount}€</b>
				</Text>
				{selectedCash.filter((c) => c.type === SavingsAccountType.CER).length > 1 && (
					<Text fontSize="sm" color="red">
						Il ne peut y avoir qu'un seul compte épargne rémunéré par client
					</Text>
				)}
				<Button colorScheme="blue" onClick={handleCreatePropale} isLoading={isCreateLoading}>
					Envoyer la proposition
				</Button>
			</HStack>
		</>
	);
};

const ProductPropaleWithBox = () => (
	<Box p="32px" mt="32px" borderRadius="10px" borderWidth="1px" borderColor="gray.200" bg="white" w="1000px">
		<ProductPropaleNoBox />
	</Box>
);

export default ProductPropaleWithBox;
