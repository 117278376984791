import { FC, useMemo } from 'react';
import { Button, HStack, Skeleton, Switch, VStack } from '@chakra-ui/react';

import FilterPopovers, { FILTER_POPOVERS } from 'components/filters';
import SearchByFilter, { SearchBy, searchByFunc } from 'components/filters/SearchBy';
import DealsTable from 'components/tables/DealsTable';
import { isDealOverdue } from 'features/ChangeOverdueDate';
import { useGetStatusesQuery } from 'services/ops/status';
import { useGetSubscriptionsQuery } from 'services/subscription';
import { BOContext, LegalEntity, ProductType, SubscriptionStatus } from 'types/global.type';
import { SavingsAccountType } from 'types/savingsAccount.type';

import useSubscriptionListFilters, { productOptions } from './utils';

type SubscriptionListProps = {
	customSearchBy?: SearchBy; // force filter at route level
	customInput?: string; // force filter at route level
	context: Extract<BOContext, 'client' | 'subscription'>;
};

const SubscriptionList: FC<SubscriptionListProps> = ({ customSearchBy, customInput, context }) => {
	const {
		data: subs,
		isFetching,
		refetch,
	} = useGetSubscriptionsQuery(
		{
			searchBy: customSearchBy ?? 'email',
			input: customInput ?? '',
		},
		{ refetchOnFocus: true, refetchOnReconnect: true, pollingInterval: 150000 },
	);
	const partnerOptions = useMemo(() => [...new Set(subs?.map((d) => d.partner))].sort(), [subs]);

	const {
		partnerFilter,
		setPartnerFilter,
		statusFilter,
		setStatusFilter,
		clientTypeFilter,
		setClientTypeFilter,
		onlyOverdue,
		setOnlyOverdue,
		includeDealsWithBI,
		setIncludeDealsWithBI,
		input,
		setInput,
		searchBy,
		setSearchBy,
		productFilter,
		setProductFilter,
		resetFilters,
	} = useSubscriptionListFilters(context, customSearchBy, customInput, partnerOptions);

	const { data: statuses } = useGetStatusesQuery();

	return (
		<VStack w="100%" align="start">
			{context === 'subscription' && (
				<HStack w="100%" justify="space-between">
					<HStack w="100%">
						<SearchByFilter
							search={input}
							onChangeSearch={setInput}
							searchBy={searchBy}
							onChangeSearchBy={setSearchBy}
							isFetching={isFetching}
							onClearCache={refetch}
						/>

						<FilterPopovers
							components={[
								{
									title: 'Produit',
									componentProps: {
										value: productFilter,
										onChange: setProductFilter,
										options: productOptions,
									},
								},
								{
									title: 'Statut',
									componentProps: {
										value: statusFilter,
										onChange: (v: string[]) => setStatusFilter(v as SubscriptionStatus[]),
										options: [SubscriptionStatus.PENDING, SubscriptionStatus.SIGNED],
									},
								},
								{
									title: 'Client type',
									componentProps: {
										onChange: setClientTypeFilter,
										options: ['isBlack', 'isPhoenix', 'isCorporate'],
										value: clientTypeFilter,
									},
								},
								{
									component: FILTER_POPOVERS.PARTNER,
									title: 'Partenaire',
									componentProps: {
										onChange: setPartnerFilter,
										options: partnerOptions,
										value: partnerFilter,
									},
								},
							]}
						/>

						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={<Switch isChecked={onlyOverdue} onChange={(event) => setOnlyOverdue(event.target.checked)} />}
						>
							Overdue
						</Button>
						<Button
							_hover={{ cursor: 'auto' }}
							rightIcon={
								<Switch
									isChecked={includeDealsWithBI}
									onChange={(event) => setIncludeDealsWithBI(event.target.checked)}
								/>
							}
						>
							Instances
						</Button>
					</HStack>
					<Button
						variant="outline"
						onClick={() => {
							resetFilters();
						}}
					>
						Reset filters
					</Button>
				</HStack>
			)}

			<Skeleton isLoaded={!isFetching} w="100%">
				<DealsTable
					context={context}
					productTypeStatuses={statuses}
					deals={(subs ?? [])
						.filter((s) => searchByFunc(s, searchBy, input))
						.filter((s) => {
							if (s.productType === ProductType.CASH && s.type === SavingsAccountType.CER)
								return productFilter.includes('CER');
							if (s.productType === ProductType.CASH && s.type === SavingsAccountType.CAT)
								return productFilter.includes(s.legalEntity === LegalEntity.MORAL ? 'CATPM' : 'CATPP');
							else if (s.productType === ProductType.CASH && s.type === SavingsAccountType.CER)
								return productFilter.includes('CER');
							else if (
								s.productType === ProductType.INVEST &&
								s.dealType === 'CONTRACT' &&
								s.structuredProduct.length > 0
							)
								return productFilter.includes('SP');
							else if (s.productType === ProductType.INVEST && s.dealType === 'TRANSFER')
								return productFilter.includes('TRANSFER');
							else return productFilter.includes(s.productType);
						})
						.filter((s) => {
							if (clientTypeFilter.length === 0) return true;
							return (
								(clientTypeFilter.includes('isBlack') && s.user.isBlack) ||
								(clientTypeFilter.includes('isPhoenix') && s.user.isPhoenix) ||
								(clientTypeFilter.includes('isCorporate') && s.user.isCorporate)
							);
						})
						.filter((s) => 'status' in s && statusFilter.includes(s.status as SubscriptionStatus))
						.filter((s) => (!includeDealsWithBI ? !s.hasBlockingInstance : true))
						.filter((s) => partnerFilter.includes(s.partner))
						.filter((s) => (onlyOverdue ? isDealOverdue(s, statuses) : true))}
					pageSize={50}
				/>
			</Skeleton>
		</VStack>
	);
};

export default SubscriptionList;
